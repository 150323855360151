import { SalesCategory, SendStatus, Static, Status } from "@graphql/crm";
import { Dict } from "@swan-io/boxed";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/lake/src/components/Filters";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useMemo, useState } from "react";
import { t } from "../../../utils/i18n";
import { staticToItem } from "../../../utils/utils";

type Props = {
  filters: InvoiceFiltersState;
  categoryItems: SalesCategory[];
  statusItems: Static[];
  sendStatusItems: Static[];
  onChange: (values: Partial<InvoiceFiltersState>) => void;
};

const statusFilter: FilterCheckboxDef<Status> = {
  type: "checkbox",
  label: t("common.status"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const sendStatusFilter: FilterCheckboxDef<SendStatus> = {
  type: "checkbox",
  label: t("common.sendStatus"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const categoryFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  label: t("invoices.saleCategory"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: [],
};

const isRectifiedFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  label: t("invoices.filters.isRectified"),
  submitText: t("common.filters.apply"),
  items: [
    {
      label: t("invoices.filters.isRectified"),
      value: "true",
    },
    {
      label: t("invoices.filters.isNotRectified"),
      value: "false",
    },
  ],
};

const filtersDefinition = {
  status: statusFilter,
  sendStatus: sendStatusFilter,
  category: categoryFilter,
  isRectified: isRectifiedFilter,
};

export type InvoiceFiltersState = FiltersState<typeof filtersDefinition> & {
  search?: string | undefined;
};

export const ListFilter = ({
  filters,
  onChange,
  categoryItems,
  statusItems,
  sendStatusItems,
}: Props) => {
  statusFilter.items = staticToItem<Status>(statusItems);
  categoryFilter.items = categoryItems?.map(row => {
    return { value: row.id, label: row.description };
  });
  sendStatusFilter.items = staticToItem<SendStatus>(sendStatusItems);

  const filtersWithoutSearch = useMemo(() => {
    const { search, ...filtersWithoutSearch } = filters;
    return filtersWithoutSearch;
  }, [filters]);

  const availableFilters: { name: keyof typeof filtersWithoutSearch; label: string }[] = useMemo(
    () => [
      {
        name: "status",
        label: t("common.status"),
      },
      {
        name: "sendStatus",
        label: t("common.sendStatus"),
      },
      {
        name: "category",
        label: t("invoices.saleCategory"),
      },
      {
        name: "isRectified",
        label: t("invoices.filters.isRectified"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filtersWithoutSearch)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filtersWithoutSearch)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filtersWithoutSearch]);

  return (
    <>
      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={value => {
          onChange({ ...value });
        }}
        onChangeOpened={setOpenFilters}
      />

      <FilterChooser
        filters={filtersWithoutSearch}
        openFilters={openFilters}
        label={t("common.filters")}
        title={""}
        onAddFilter={filter => {
          setOpenFilters(openFilters => [...openFilters, filter]);
        }}
        availableFilters={availableFilters}
      />
    </>
  );
};
