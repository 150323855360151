import { useNestedForm } from "@components/NestedForm";
import { RightPanel } from "@components/RightPanel";
import { UploadShowLogoCompany } from "@components/UploadShowLogoCompany";
import { Button, ButtonGroup } from "@components/forms/Button";
import { DatePicker } from "@components/forms/DatePicker";
import Input from "@components/forms/Input";
import {
  CalculateInvoiceDocument,
  ConceptType,
  CreateSalesInvoiceDocument,
  CustomerAddress,
  CustomerRelay,
  CustomerRelayEdge,
  CustomersDocument,
  GetDeliveryCurrentSeriesDocument,
  GetProformaCurrentSeriesDocument,
  GetQuoteCurrentSeriesDocument,
  InvoiceTotals,
  SalesConceptInput,
  SalesInvoiceCreateStaticDocument,
  SalesInvoiceRelayQuery,
  Status,
  UpdateSalesInvoiceDocument,
} from "@graphql/crm";
import { AsyncData, Result } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { RadioGroup } from "@swan-io/lake/src/components/RadioGroup";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useDebounce } from "@swan-io/lake/src/hooks/useDebounce";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { CountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { printIbanFormat, validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { P, match } from "ts-pattern";
import { common } from "../../../../styles/common";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../../styles/constants";
import { encodeDate, encodeDateISO, getToday } from "../../../../utils/date";
import { locale, t } from "../../../../utils/i18n";
import { SaveIcon } from "../../../../utils/icons";
import { Router } from "../../../../utils/routes";
import {
  extractQueryData,
  handlerErrors,
  passValidatorTrue,
  tapError,
  validateHasItems,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../../utils/validations";
import { AddressForm } from "../../../contacts/components/AddressForm";
import { CustomerCreate } from "../../../contacts/components/CustomerCreate";
import { useInvoiceHistory } from "../../hooks/useInvoiceHistory";
import { Concept, CreateInvoiceState, CreateSalesConceptState } from "../../types";
import { extractSalesStatic } from "../../utils";
import { ExpirationDate } from "../ExpirationDate";
import { InvoiceTotal } from "../InvoiceTotal";
import { Retention } from "../Retention";
import { SalesConceptItem } from "../SalesConceptItem";
import { SalesSuplidoConceptItem } from "../SalesSuplidoConceptItem";
import { Tabs } from "../Tabs";

const styles = StyleSheet.create({
  contain: {
    backgroundColor: backgroundColorVariants.white,
    paddingHorizontal: 32,
    paddingVertical: 56,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.10)",
    width: "96dvw",
    maxWidth: 900,
    marginBottom: 16,
  },
  title: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 24,
  },
  paymentMethod: {
    display: "grid",
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 16,
  },
  columnMin: {
    width: 300,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
    minWidth: 190,
  },
  gap: {
    gap: 12,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  uploadArea: {
    "--spacing-32": 16,
    display: "flex",
    gap: 24,
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  action: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 4,
  },
  actionText: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
  newItem: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 24,
    boxShadow: "none",
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
  },
  typeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

type Props = {
  invoice?: SalesInvoiceRelayQuery;
  onRefreshRequest?: () => void;
  isQuote: boolean;
  isProforma: boolean;
  isDelivery: boolean;
};

export const ProformaEditor = ({
  invoice,
  isQuote,
  isProforma,
  isDelivery,
  onRefreshRequest,
}: Props) => {
  const route = Router.useRoute(["InvoicesSalesDeliveryCreate"]);
  const { quoteId } = route?.params ?? {};

  const { onSubmit, setMainForm } = useNestedForm();

  const getNewConcept = (type = "normal") => ({
    id: crypto.randomUUID(),
    description: "",
    price: 0,
    quantity: 1,
    expenseType: "corriente",
    irpfId: "",
    vatId: "",
    recargoPercentageId: "",
    type,
  });

  const [, calculateInvoice] = useUrqlMutation(CalculateInvoiceDocument);
  const [paymentMethodType, setPaymentMethodType] = useState("");
  const [totals, setTotals] = useState<InvoiceTotals>();
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState<string>();

  const [showCustomerCreate, setShowCustomerCreate] = useState(false);

  const [customer, setCustomer] = useState<CustomerRelay | undefined>();
  const { data: customersQuery, reload: reloadCustomers } = useUrqlQuery(
    { query: CustomersDocument, variables: { first: 100, filters: {} } },
    [],
  );
  const customers = extractQueryData(
    customersQuery,
    "value.value.customers.edges",
  ) as CustomerRelayEdge[];

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);
  match(data).with(AsyncData.P.Done(Result.P.Error(P.select())), tapError);
  const paymentMethods = extractSalesStatic(data, "paymentMethods");
  const expirationDate = extractSalesStatic(data, "expirationDate");
  const conceptType = extractSalesStatic(data, "conceptType");
  const statusType = extractSalesStatic(data, "statusType");

  const history = useInvoiceHistory({ salesInvoiceId: invoice?.id as string });

  const logoUrl = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(result => result.salesInvoiceCreateStatic.logoUrl)
    .getWithDefault("");

  const { data: quoteSeriesData } = useUrqlQuery(
    { query: GetQuoteCurrentSeriesDocument, pause: !isQuote },
    [],
  );

  const currentQuoteSeries = extractQueryData(
    quoteSeriesData,
    "value.value.getQuoteCurrentSeries.series",
  ) as string;

  const { data: ProformaSeriesData } = useUrqlQuery(
    { query: GetProformaCurrentSeriesDocument, pause: !isProforma },
    [],
  );

  const currentProformaSeries = extractQueryData(
    ProformaSeriesData,
    "value.value.getProformaCurrentSeries.series",
  ) as string;

  const { data: deliverySeriesData } = useUrqlQuery(
    { query: GetDeliveryCurrentSeriesDocument, pause: !isDelivery },
    [],
  );

  const currentDeliverySeries = extractQueryData(
    deliverySeriesData,
    "value.value.getDeliveryCurrentSeries.series",
  ) as string;

  const conceptForm = () => {
    if (invoice === undefined) {
      return [getNewConcept()];
    }
  };

  const [submit, setSubmit] = useState(false);

  const { Field, setFieldValue, getFieldState, submitForm, listenFields } =
    useForm<CreateInvoiceState>({
      customerId: {
        initialValue:
          invoice?.customer?.id !== undefined ? Number(invoice?.customer.id) : undefined,
        validate: passValidatorTrue(validateNumericNullableRequired, !submit),
      },
      customerAddress: { initialValue: invoice?.customerAddress ?? "", validate: validateRequired },
      customerLocality: { initialValue: invoice?.customerLocality ?? "" },
      customerPostalCode: { initialValue: invoice?.customerPostalCode ?? "" },
      customerCountry: { initialValue: invoice?.customerCountry as CountryCCA3 },
      seriesCurrent: { initialValue: "" },
      issueDate: {
        initialValue: invoice?.issueDate != null ? encodeDateISO(invoice?.issueDate) : getToday(),
        validate: passValidatorTrue(validateRequired, !submit),
      },
      expirationDate: {
        initialValue: invoice?.expirationDate != null ? encodeDateISO(invoice?.expirationDate) : "",
        validate: passValidatorTrue(validateRequired, !submit),
        strategy: "onSubmit",
      },
      concepts: {
        initialValue: conceptForm() as Concept[],
        validate: passValidatorTrue(validateHasItems, !submit),
      },
      status: { initialValue: isQuote ? "QUOTE" : isDelivery ? "DELIVERY" : "PROFORMA" },
      type: { initialValue: (invoice?.type as string) ?? null, validate: validateRequired },
      paymentMethod: { initialValue: invoice?.paymentMethod ?? "" },
      iban: { initialValue: invoice?.iban ?? "", validate: validateIban },
      notes: { initialValue: invoice?.notes ?? "" },
      retentionPercentage: { initialValue: invoice?.retentionPercentage as string },
      retentionMode: { initialValue: invoice?.retentionMode ?? "tax_base" },
    });

  const callCalculateInvoice = () => {
    const { value } = getFieldState("concepts");
    calculateInvoice({
      input: {
        lineItems: value.map(concept => ({
          description: concept.description,
          quantity: concept.quantity != null ? (concept.quantity > 0 ? concept.quantity : 1) : 1,
          unitPrice: concept.unitPrice === "" ? undefined : concept.unitPrice,
          subtotal: concept.subtotal === "" ? undefined : concept.subtotal,
          discountPercentage: concept.discountPercentage,
          discountAmount: concept.discountAmount,
          vatRate: isNotNullish(concept.vatId) ? String(concept.vatId) : null,
          irpfPercentage: isNotNullish(concept.irpfId) ? String(concept.irpfId) : null,
          total: concept.price,
          type: concept.type.toUpperCase() as ConceptType,
          recargoPercentage: isNotNullishOrEmpty(concept.recargoPercentageId)
            ? String(concept.recargoPercentageId)
            : null,
        })),
        retentionPercentage: getFieldState("retentionPercentage").value,
        retentionMode: getFieldState("retentionMode").value,
      },
    }).mapOk(data => {
      const lineItems = extractQueryData(data.calculateInvoice, "lineItems") as Concept[];

      let isCalculated = false;
      value.forEach((concept, index) => {
        const lineItem = lineItems[index];
        if (lineItem) {
          concept.total = lineItem.total as string;
          concept.discountAmount = lineItem.discountAmount;
          concept.irpfAmount = lineItem.irpfAmount as string;
          concept.vatAmount = lineItem.vatAmount as string;
          concept.taxBase = lineItem.taxBase as string;
          concept.price = Number(lineItem.total);
          concept.recargoAmount = Number(lineItem.recargoAmount);

          if (concept.unitPrice != lineItem.unitPrice) {
            concept.unitPrice = lineItem.unitPrice as string;
            isCalculated = true;
          }
          if (concept.subtotal != lineItem.subtotal) {
            concept.subtotal = lineItem.subtotal as string;
            isCalculated = true;
          }
        }
      });
      if (isCalculated) {
        setFieldValue("concepts", [...value]);
      }

      const totals = extractQueryData(data.calculateInvoice, "totals") as InvoiceTotals;
      setTotals(totals);
    });
  };

  const refresh = useDebounce<void>(() => callCalculateInvoice(), 500);

  useEffect(() => {
    const removeResultsListener = listenFields(
      ["concepts", "retentionPercentage", "retentionMode"],
      () => refresh(),
    );

    return () => removeResultsListener();
  }, [listenFields, refresh]);

  useEffect(() => {
    if (invoice !== undefined) {
      const concepts: CreateSalesConceptState[] = [];
      invoice?.concepts.forEach(concept => {
        concepts.push({
          id: concept.id ?? "",
          productId: concept.product?.pk !== undefined ? Number(concept.product?.pk) : -1,
          description: concept.description ?? "",
          unitType: concept.unitType ?? "",
          unitPrice: concept.unitPrice as string,
          price: Number(concept.price),
          vatId: isNotNullish(concept.vatId) ? String(concept.vatId) : "",
          quantity: concept.quantity,
          irpfId: isNotNullish(concept.irpfId) ? String(concept.irpfId) : "",
          discountAmount: concept.discountAmount as number,
          discountPercentage: concept.discountPercentage as number,
          expenseType: concept.expenseType ?? "",
          subtotal: concept.subtotal as string,
          type: concept.type,
          recargoPercentageId: isNotNullish(concept.recargoPercentageId)
            ? String(concept.recargoPercentageId)
            : "",
          category: String(concept.category) ?? undefined,
          subcategory: String(concept.subcategory) ?? undefined,
          subsubcategory: concept.subsubcategory ?? undefined,
        });
      });
      setFieldValue(
        "customerId",
        Boolean(invoice.customer) ? Number(invoice.customer?.id) : undefined,
      );
      if (quoteId == null) {
        setFieldValue("seriesCurrent", invoice.seriesCurrent as string);
      }
      setFieldValue("issueDate", encodeDateISO(invoice.issueDate as string));
      setFieldValue("expirationDate", encodeDateISO(invoice.expirationDate as string));
      setFieldValue("concepts", concepts);
      setFieldValue("paymentMethod", invoice.paymentMethod as string);
      setFieldValue("iban", invoice.iban as string);
      setFieldValue("notes", invoice.notes as string);
      setFieldValue("type", invoice?.type);
      callCalculateInvoice();
    }
  }, [invoice]);

  useEffect(() => {
    setOpen(!getFieldState("type").value);
  }, [getFieldState("type").value]);

  useEffect(() => {
    setFieldValue(
      "seriesCurrent",
      currentQuoteSeries ??
        currentProformaSeries ??
        currentDeliverySeries ??
        invoice?.seriesCurrent,
    );
  }, [currentQuoteSeries, currentProformaSeries, currentDeliverySeries, invoice]);

  const handleNewConcept = (type: string) => {
    const newConcept = getNewConcept(type);

    const { value } = getFieldState("concepts");
    setCurrentId(newConcept.id);
    setFieldValue("concepts", [...value, newConcept]);
  };

  const [, createInvoice] = useUrqlMutation(CreateSalesInvoiceDocument);
  const [, updateInvoice] = useUrqlMutation(UpdateSalesInvoiceDocument);

  const handleSubmit = () => {
    if (invoice && quoteId != null) {
      invoice.id = "audio";
    }
    submitForm(values => {
      const input = {
        ...values,
        status: (isQuote ? "QUOTE" : isDelivery ? "DELIVERY" : "PROFORMA") as Status,
        customerId: values.customerId as number,
        issueDate: values.issueDate !== undefined ? encodeDate(values.issueDate) : "",
        expirationDate:
          values.expirationDate !== undefined ? encodeDate(values.expirationDate) : "",
        totalDiscount: totals?.totalDiscount ?? 0,
        totalVat: totals?.totalVat ?? 0,
        totalTaxBase: totals?.totalTaxBase ?? 0,
        totalIrpf: totals?.totalIrpf ?? 0,
        subtotal: totals?.subtotal ?? 0,
        total: totals?.total ?? 0,
        retentionAmount: totals?.retention?.amount,
      };
      if (invoice !== undefined && invoice.id !== "audio") {
        Object.assign(input, { id: invoice.id });
        updateInvoice({
          input: {
            ...input,
            concepts: values.concepts?.map(concept => {
              if (concept.productId === -1) {
                concept.productId = undefined;
              }
              const { category, subcategory, ...cleanConcept } = concept;
              if (concept.id.length === 36) {
                const { id, ...rest } = cleanConcept;
                return rest;
              }
              return cleanConcept;
            }) as SalesConceptInput[],
          },
        })
          .mapOk(data => {
            match(data.updateSalesInvoice)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                Router.push("InvoicesDocumentsList");
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      } else {
        createInvoice({
          input: {
            ...input,
            concepts: values.concepts?.map(concept => {
              const { id, category, subcategory, ...rest } = concept;
              if (rest.productId === -1) {
                rest.productId = undefined;
              }
              return rest;
            }) as SalesConceptInput[],
          },
        })
          .mapOk(data => {
            match(data.createSalesInvoice)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                Router.push("InvoicesDocumentsList");
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      }
    });
  };

  const onClick = () => {
    setSubmit(true);
    setMainForm(() => handleSubmit);
  };

  useEffect(() => {
    if (submit) {
      onSubmit();
      setSubmit(false);
    }
  }, [submit]);

  const customerAux = customer ?? invoice?.customer;

  const addresses = useMemo(() => {
    if (customers == null) {
      return [];
    }
    return customers
      .find(c => c.node.id === customerAux?.id)
      ?.node.addresses.filter(a => a.type === "delivery")
      .sort(a => (a.default ? -1 : 1));
  }, [customerAux, customers]);

  const [addressId, setAddressId] = useState<string>();
  const [customerAddressModal, setCustomerAddressModal] = useState<CustomerAddress>();

  const getAddressById = (id?: string) => {
    return addresses?.find(a => a.id === id);
  };

  const getAddressByInvoice = (invoice?: SalesInvoiceRelayQuery) => {
    return addresses?.find(
      a =>
        a.address === invoice?.customerAddress &&
        a.locality === invoice?.customerLocality &&
        a.postalCode === invoice?.customerPostalCode &&
        a.country === invoice?.customerCountry,
    );
  };

  useEffect(() => {
    if (addressId != null) {
      const address = getAddressById(addressId);
      if (address != null) {
        return;
      }
    }
    const address = getAddressByInvoice(invoice);
    if (address) {
      setAddressId(address?.id);
      return;
    }
    const defaultCustomerAddress = addresses?.find(a => a.default) ?? addresses?.[0];
    setAddressId(defaultCustomerAddress?.id);
  }, [customerAux]);

  useEffect(() => {
    const address = getAddressById(addressId);
    setFieldValue("customerAddress", address?.address ?? "");
    setFieldValue("customerLocality", address?.locality ?? "");
    setFieldValue("customerPostalCode", address?.postalCode ?? "");
    setFieldValue("customerCountry", address?.country as CountryCCA3);
  }, [addresses, addressId]);

  const label =
    (isQuote
      ? t("invoices.validDate")
      : isDelivery
        ? t("invoices.receptionDate")
        : t("invoices.dueDate")) + "*";

  return (
    <View>
      <Field name="type">{() => null}</Field>
      <Field name="retentionPercentage">{() => null}</Field>
      <Field name="retentionMode">{() => null}</Field>
      <Tabs history={history} status={statusType} onPress={() => setCurrentId("")} />
      <Space height={12} />

      <ResponsiveContainer breakpoint={breakpoints.medium}>
        {({ large }) => (
          <View style={styles.contain}>
            <View>
              <Box
                direction={large ? "row" : "column"}
                alignItems={large ? "end" : "stretch"}
                justifyContent="spaceBetween"
                style={styles.gap}
              >
                <View style={styles.columnMin}>
                  <View style={styles.uploadArea}>
                    <UploadShowLogoCompany logoUrl={logoUrl} />
                  </View>

                  <Space height={48} />

                  <Text style={styles.title}>
                    {isQuote
                      ? t("budget")
                      : isDelivery
                        ? t("deliveryNote")
                        : `${t("invoice")} ${t("invoice.proforma")}`}
                  </Text>

                  <Field name="seriesCurrent">
                    {({ value }) => (
                      <LakeLabel
                        label={
                          isQuote
                            ? t("budget.number")
                            : isDelivery
                              ? t("deliveryNote.number")
                              : t("invoices.invoiceNumber")
                        }
                        render={() => (
                          <LakeTextInput
                            style={styles.actions}
                            value={value}
                            disabled={true}
                            hideErrors={true}
                          />
                        )}
                        style={styles.actions}
                      />
                    )}
                  </Field>

                  <Space height={20} />
                </View>

                <View style={large && styles.column}>
                  <Box
                    direction="row"
                    justifyContent="spaceBetween"
                    style={[styles.gap, common.wrap]}
                  >
                    <View style={styles.column}>
                      <Field name="issueDate">
                        {({ value, onChange, error }) => (
                          <DatePicker
                            format={locale.dateFormat}
                            firstWeekDay={locale.firstWeekday}
                            value={value}
                            onChange={value => {
                              onChange(value);
                              setFieldValue("expirationDate", "");
                            }}
                            label={t("invoices.issueDate") + "*"}
                            error={error}
                          />
                        )}
                      </Field>
                    </View>

                    <View style={styles.column}>
                      <Field name="expirationDate">
                        {({ value, onChange, error }) => (
                          <ExpirationDate
                            label={label}
                            items={expirationDate}
                            forceCalendar={invoice?.expirationDate != null || isDelivery}
                            issueDate={getFieldState("issueDate").value}
                            value={value}
                            onChange={onChange}
                            error={error}
                          />
                        )}
                      </Field>
                    </View>
                  </Box>

                  <Text style={styles.subtitle}>{t("invoices.clientData")}</Text>

                  <Field name="customerId">
                    {({ value, onChange, error }) => (
                      <LakeLabel
                        label={t("invoices.customer")}
                        render={() => (
                          <LakeSelect
                            items={
                              customers?.map(item => ({
                                name: `${item.node.name} ${item.node.nif != null ? ` (${item.node.nif})` : ""}`,
                                value: parseInt(item.node.id as string),
                              })) ?? []
                            }
                            value={value}
                            onValueChange={value => {
                              setCustomer(
                                customers?.find(c => c.node.id === value.toString())?.node,
                              );
                              onChange(value);
                            }}
                            hideErrors={error === undefined}
                            error={error}
                          />
                        )}
                        style={styles.actions}
                        actions={
                          <Box direction="row" justifyContent="spaceBetween">
                            <Pressable
                              style={styles.action}
                              onPress={() => {
                                setFieldValue("customerId", undefined);
                                setCustomer(undefined);
                                setShowCustomerCreate(true);
                              }}
                            >
                              <Icon
                                name="add-filled"
                                size={12}
                                color={fontColorVariants.neutral500}
                              />

                              <Text style={styles.actionText}>{t("invoices.newCustomer")}</Text>
                            </Pressable>

                            {value != null && (
                              <Pressable
                                style={styles.action}
                                onPress={() => {
                                  setCustomer(
                                    customers?.find(c => c.node.id === value.toString())?.node,
                                  );
                                  setShowCustomerCreate(true);
                                }}
                              >
                                <Icon
                                  name="edit-regular"
                                  size={12}
                                  color={fontColorVariants.neutral500}
                                />

                                <Text style={styles.actionText}>{t("invoices.editCustomer")}</Text>
                              </Pressable>
                            )}
                          </Box>
                        }
                      />
                    )}
                  </Field>

                  {isDelivery && (
                    <>
                      <Space height={16} />
                      <Field name="customerLocality">{() => null}</Field>
                      <Field name="customerPostalCode">{() => null}</Field>
                      <Field name="customerCountry">{() => null}</Field>

                      <Field name="customerAddress">
                        {({ error }) => (
                          <LakeLabel
                            label={t("address.delivery")}
                            render={() => (
                              <LakeSelect
                                items={
                                  addresses?.map(item => ({
                                    name: `${item.address} ${item.postalCode} ${item.locality}${item.default ? ` (${t("common.main")})` : ""}`,
                                    value: item.id,
                                  })) ?? []
                                }
                                value={addressId}
                                onValueChange={setAddressId}
                                error={error}
                                hideErrors={error === undefined}
                                disabled={!customerAux}
                              />
                            )}
                            style={styles.actions}
                            actions={
                              <Box direction="row" justifyContent="spaceBetween">
                                {!customerAux && <Space height={20} />}

                                {customerAux && (
                                  <Pressable
                                    style={styles.action}
                                    onPress={() => {
                                      setCustomerAddressModal({
                                        type: "delivery",
                                      } as CustomerAddress);
                                    }}
                                  >
                                    <Icon
                                      name="add-filled"
                                      size={12}
                                      color={fontColorVariants.neutral500}
                                    />

                                    <Text style={styles.actionText}>{t("company.newAddress")}</Text>
                                  </Pressable>
                                )}

                                {addressId != null && (
                                  <Pressable
                                    style={styles.action}
                                    onPress={() => {
                                      setCustomerAddressModal(getAddressById(addressId));
                                    }}
                                  >
                                    <Icon
                                      name="edit-regular"
                                      size={12}
                                      color={fontColorVariants.neutral500}
                                    />

                                    <Text style={styles.actionText}>{t("common.editAddress")}</Text>
                                  </Pressable>
                                )}
                              </Box>
                            }
                          />
                        )}
                      </Field>

                      <LakeModal
                        visible={customerAddressModal != null}
                        title={t("address.delivery")}
                        maxWidth={740}
                        onPressClose={() => {
                          setCustomerAddressModal(undefined);
                        }}
                      >
                        <AddressForm
                          customerId={parseInt(customerAux?.id as string)}
                          address={customerAddressModal}
                          onClose={() => {
                            reloadCustomers();
                            setCustomerAddressModal(undefined);
                          }}
                        />
                      </LakeModal>
                    </>
                  )}

                  {showCustomerCreate && (
                    <CustomerCreate
                      visible={showCustomerCreate}
                      customer={customer}
                      onClose={() => {
                        reloadCustomers();
                        setShowCustomerCreate(false);
                      }}
                    />
                  )}
                </View>
              </Box>

              <Space height={32} />

              <Field name="concepts">
                {({ value, onChange, error }) => (
                  <Box style={styles.gap}>
                    {value?.map(concept =>
                      concept.type === "suplido" ? (
                        <SalesSuplidoConceptItem
                          key={concept.id}
                          isActive={currentId === concept.id}
                          setCurrentId={() => setCurrentId(concept.id)}
                          validate={!submit}
                          concept={concept}
                          deleteDisabled={value.length === 1}
                          onChange={concept =>
                            onChange(
                              value.map(c => (c.id === concept.id ? { ...c, ...concept } : c)),
                            )
                          }
                          onDelete={() => onChange(value.filter(c => c.id !== concept.id))}
                        />
                      ) : (
                        <SalesConceptItem
                          key={concept.id}
                          isActive={currentId === concept.id}
                          setCurrentId={() => setCurrentId(concept.id)}
                          validate={!submit}
                          concept={concept}
                          deleteDisabled={value.length === 1}
                          onChange={concept =>
                            onChange(
                              value.map(c => (c.id === concept.id ? { ...c, ...concept } : c)),
                            )
                          }
                          onDelete={() => onChange(value.filter(c => c.id !== concept.id))}
                          invoiceType={getFieldState("type").value}
                          recargo={concept.type === "recargo_equivalencia"}
                        />
                      ),
                    )}

                    <LakeSelect
                      placeholder={t("invoices.addNewItem")}
                      hideErrors={true}
                      items={conceptType}
                      onValueChange={handleNewConcept}
                      style={styles.newItem}
                    />

                    <Text style={styles.red}>{error}</Text>
                  </Box>
                )}
              </Field>

              <Field name="notes">
                {Input({
                  label: t("invoices.notes"),
                })}
              </Field>

              <Space height={24} />

              <Box
                direction="row"
                justifyContent="spaceBetween"
                alignItems="end"
                style={styles.gap}
              >
                <Box direction="column" style={[styles.column, styles.gap, styles.paymentMethod]}>
                  <Retention
                    invoice={invoice}
                    onChange={values => {
                      setFieldValue("retentionPercentage", values.retentionPercentage?.value);
                      setFieldValue("retentionMode", values.retentionMode?.value);
                    }}
                  />

                  <Field name="paymentMethod">
                    {({ value, onChange, error, ref }) => (
                      <LakeLabel
                        label={t("contact.paymentMethod")}
                        render={id => (
                          <LakeSelect
                            id={id}
                            ref={ref}
                            value={value}
                            hideErrors={error === undefined}
                            error={error}
                            items={paymentMethods}
                            onValueChange={value => {
                              onChange(value);
                              setPaymentMethodType(value);
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>

                  {["transferencia", "domiciliacion"].includes(paymentMethodType) && (
                    <LakeLabel
                      label={t("contact.iban")}
                      render={id => (
                        <Field name="iban">
                          {({ value, onChange, onBlur, error, validating, ref }) => (
                            <LakeTextInput
                              id={id}
                              ref={ref}
                              value={printIbanFormat(value ?? "")}
                              validating={validating}
                              hideErrors={error === undefined}
                              error={error}
                              onChangeText={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        </Field>
                      )}
                    />
                  )}
                </Box>

                <InvoiceTotal totals={totals} />
              </Box>
            </View>
          </View>
        )}
      </ResponsiveContainer>

      <Box direction="row" justifyContent="end">
        <Button
          mode="tertiary"
          size="large"
          disabled={false}
          onPress={() => Router.push("InvoicesDocumentsList", { visible: undefined })}
        >
          {t("common.cancel")}
        </Button>

        <ButtonGroup transparent={false}>
          <Button
            style="group"
            size="large"
            disabled={false}
            icon={<SaveIcon />}
            reverse={true}
            onPress={onClick}
          >
            {t("common.save")}
          </Button>
        </ButtonGroup>
      </Box>

      <RightPanel visible={open} overlay={true}>
        <View style={styles.rightpanel}>
          <View style={styles.typeContainer}>
            <LakeLabel
              label={t("invoice.type")}
              render={() => (
                <Field name="type">
                  {({ value, onChange }) => (
                    <RadioGroup
                      items={[
                        { name: t("invoice.current"), value: "current" },
                        { name: t("invoice.nonCurrent"), value: "non_current" },
                      ]}
                      value={value}
                      direction="row"
                      onValueChange={value => {
                        setOpen(false);
                        onChange(value);
                      }}
                    />
                  )}
                </Field>
              )}
            />
          </View>
        </View>
      </RightPanel>
    </View>
  );
};
