import { DecimalInput } from "@components/DecimalInput";
import { Button } from "@components/forms/Button";
import Input from "@components/forms/Input";
import Select from "@components/forms/Select";
import {
  CreateProductDocument,
  Product,
  SalesCategory,
  SalesInvoiceCreateStaticDocument,
  SalesSubCategory,
  SalesSubSubCategory,
  UpdateProductDocument,
} from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useState } from "react";
import { StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { fontColorVariants } from "../../../styles/constants";
import { toNumber } from "../../../utils/decimal";
import { t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { subscriptionError, useSubscription } from "../../../utils/subscription";
import { CreateProductState } from "../../../utils/types";
import { extractQueryData, validateRequired } from "../../../utils/validations";
import { extractSalesStatic, extractSalesTax } from "../utils";

const styles = StyleSheet.create({
  grid: {
    display: "grid",
    alignItems: "flex-start",
    gridTemplateColumns: "1fr 1fr",
    gap: 12,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "75%",
    alignSelf: "flex-end",
    gap: 48,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
});

type Props = {
  product?: Product;
  onPressClose?: () => void;
  showCancel: boolean;
};

export const ProductEditor = ({ product, onPressClose, showCancel }: Props) => {
  const [subcategories, setSubcategories] = useState<SalesSubCategory[]>([]);
  const [subsubcategories, setSubsubcategories] = useState<SalesSubSubCategory[]>([]);
  const { Field, submitForm, resetForm, getFieldState, listenFields, setFieldValue } =
    useForm<CreateProductState>({
      description: { initialValue: product?.description ?? "", validate: validateRequired },
      unitType: { initialValue: product?.unitType ?? "" },
      unitPrice: { initialValue: product?.unitPrice as string, validate: validateRequired },
      vat: {
        initialValue: isNotNullish(product?.vatId) ? String(product?.vatId) : "",
        validate: validateRequired,
      },
      irpf: { initialValue: isNotNullish(product?.irpfId) ? String(product?.irpfId) : "" },
      category: { initialValue: product?.category?.toString() ?? "" },
      subcategory: { initialValue: "" },
      subsubcategory: { initialValue: "" },
    });

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const [, createProduct] = useUrqlMutation(CreateProductDocument);
  const [, updateProduct] = useUrqlMutation(UpdateProductDocument);

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);
  const SalesCreateStaticData = extractQueryData(data, "value.value.salesInvoiceCreateStatic");

  const categories = extractQueryData(
    SalesCreateStaticData,
    "currentCategories",
  ) as SalesCategory[];

  useEffect(() => {
    const subcategory = product?.subcategory?.toString() ?? "";
    fillCategories(product?.category?.toString() ?? "", subcategory);
    setFieldValue("subcategory", subcategory);
    setFieldValue("subsubcategory", product?.subsubcategory?.toString() ?? "");
  }, [product]);

  const fillCategories = (category: string, subcategory: string) => {
    const categoryObj = categories.find(c => c.id == category);
    if (categoryObj !== undefined) {
      setSubcategories(categoryObj.subcategory);
      const subcategoryObj = categoryObj.subcategory.find(c => c.id == subcategory);
      if (subcategoryObj !== undefined) {
        setSubsubcategories(subcategoryObj?.subsubcategory ?? []);
      }
    }
  };

  useEffect(() => {
    const listener = listenFields(["category", "subcategory"], ({ category, subcategory }) => {
      fillCategories(category.value, subcategory.value);
    });

    return () => listener();
  }, [listenFields, data]);

  const unitType = extractSalesStatic(data, "unitType");
  const irpfType = extractSalesTax(data, "irpfType");
  const vatType = extractSalesTax(data, "vatType");

  const { showUpgrade } = useSubscription();

  const onSave = () => {
    submitForm(values => {
      if (product === undefined) {
        createProduct({
          input: {
            description: values.description ?? "",
            unitType: values.unitType,
            unitPrice: parseFloat(values.unitPrice ?? ""),
            vatId: values.vat !== undefined ? Number(values.vat) : undefined,
            irpfId: values.irpf !== undefined ? Number(values.irpf) : undefined,
            subsubcategoryId:
              values.subsubcategory !== undefined ? parseInt(values.subsubcategory) : null,
          },
        }).mapOk(data => {
          match(data.createProduct)
            .with({ __typename: "OperationInfo" }, info => {
              showUpgrade({
                title: t("plan.limitTitle"),
                description: subscriptionError(info),
              });
            })
            .otherwise(() => onPressClose?.());
        });
      } else {
        updateProduct({
          input: {
            id: product.id,
            description: values.description ?? "",
            unitType: values.unitType,
            unitPrice: parseFloat(values.unitPrice ?? ""),
            vatId: values.vat !== undefined ? toNumber(values.vat) : undefined,
            irpfId: values.irpf !== undefined ? toNumber(values.irpf) : undefined,
            subsubcategoryId:
              values.subsubcategory !== undefined ? parseInt(values.subsubcategory) : null,
          },
        }).mapOk(() => {
          onPressClose?.();
        });
      }
    });
  };

  return (
    <>
      <Field name="description">
        {Input({
          label: t("invoices.description"),
          required: true,
        })}
      </Field>

      <Space height={12} />

      <Box style={styles.grid}>
        <Field name="unitType">
          {Select({
            label: t("invoices.unitType"),
            items: unitType,
            hasEmptyRow: true,
          })}
        </Field>

        <Field name="unitPrice">
          {({ value, onChange, error }) => (
            <LakeLabel
              label={t("invoices.unitPrice")}
              extra={() => <Text style={styles.red}>*</Text>}
              render={id => (
                <DecimalInput id={id} value={value} error={error} onChangeDecimal={onChange} />
              )}
            />
          )}
        </Field>
      </Box>

      <Space height={12} />

      <Box style={styles.grid}>
        <Field name="vat">
          {Select({
            label: t("invoices.iva"),
            items: vatType.map(({ id, description }) => ({ name: description, value: id })),
            required: true,
          })}
        </Field>

        <Field name="irpf">
          {Select({
            label: t("invoices.irpf"),
            items: irpfType.map(({ id, description }) => ({ name: description, value: id })),
            hasEmptyRow: true,
          })}
        </Field>
      </Box>

      <Space height={12} />

      <Box style={styles.grid}>
        <Field name="category">
          {Select({
            label: t("invoices.category"),
            items: categories.map(({ id, description }) => ({ name: description, value: id })),
            hasEmptyRow: true,
            customOnChange: value => {
              setFieldValue("category", value);
              setFieldValue("subcategory", "");
              setFieldValue("subsubcategory", "");
            },
          })}
        </Field>

        <Field name="subcategory">
          {Select({
            label: t("invoices.subcategory"),
            items: subcategories.map(({ id, description }) => ({ name: description, value: id })),
            hasEmptyRow: true,
            disabled: !getFieldState("category").value,
            customOnChange: value => {
              setFieldValue("subcategory", value);
              setFieldValue("subsubcategory", "");
            },
          })}
        </Field>
      </Box>

      <Box style={styles.grid}>
        <Field name="subsubcategory">
          {Select({
            label: t("invoices.subsubcategory"),
            items: subsubcategories.map(({ id, description }) => ({
              name: description,
              value: id,
            })),
            hasEmptyRow: true,
            disabled: !getFieldState("subcategory").value,
          })}
        </Field>
      </Box>

      <Space height={24} />

      <Box style={showCancel === true && styles.gap} alignItems="end">
        {showCancel === true ? (
          <Button onPress={onPressClose} mode="secondary">
            {t("common.cancel")}
          </Button>
        ) : null}

        <Button onPress={onSave} icon={<SaveIcon />}>
          {t("common.save")}
        </Button>
      </Box>
    </>
  );
};
