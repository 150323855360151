import { Calendar } from "@components/Calendar";
import { Heading } from "@components/Heading";
import { InputRounded } from "@components/forms/Input";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { t } from "../../../utils/i18n";
import { COLORS } from "../../../values/colors";

const styles = StyleSheet.create({
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  heading: {
    zIndex: 1,
  },
  filters: {
    gap: 16,
  },
});

export const IncomeExpense = () => {
  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="spaceBetween" style={styles.header}>
        <InputRounded>
          <LakeSearchField
            placeholder={t("common.search")}
            initialValue={""}
            onChangeText={() => {}}
          />
        </InputRounded>

        <Box direction="row" style={styles.filters}>
          <Calendar />
        </Box>
      </Box>

      <ResponsiveContainer breakpoint={breakpoints.medium}>
        {({ large }) => (
          <Box
            direction={large ? "row" : "column"}
            alignItems="center"
            justifyContent="spaceBetween"
            style={styles.heading}
          >
            <Heading title={t("reports.incomeExpense")} />

            <View>
              <Space height={12} />
            </View>
          </Box>
        )}
      </ResponsiveContainer>

      <></>
    </>
  );
};
