import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text } from "react-native";
import { common } from "../../../styles/common";
import { borderColorVariants, fontColorVariants } from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";
import { Concept } from "../types";

const styles = StyleSheet.create({
  column: {
    flexBasis: "calc(50% - 12px)",
    gap: 6,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "80%",
    gap: 12,
  },
  resume: {
    fontSize: 14,
    fontWeight: "400",
    color: fontColorVariants.neutral500,
  },
  total: {
    borderTopWidth: 1,
    borderTopColor: borderColorVariants.gray400,
    marginTop: 6,
    paddingTop: 6,
  },
  totalText: {
    fontSize: 18,
    fontWeight: "500",
    color: fontColorVariants.neutral600,
  },
});

type Props = {
  concept: Concept;
};

export const ConceptTotal = ({ concept }: Props) => {
  const {
    vatAmount,
    subtotal,
    total,
    recargoPercentageId,
    recargoAmount,
    discountAmount,
    irpfAmount,
    taxBase,
  } = concept;

  return (
    <Box alignItems="end" style={styles.column}>
      <Line label={t("invoices.subtotal")} value={subtotal} />

      {discountAmount != null && discountAmount != 0 && (
        <Line label={t("invoices.totalDiscount")} value={-discountAmount} />
      )}

      <Line label={t("invoices.taxBase")} value={taxBase} />
      <Line label={t("invoices.iva")} value={vatAmount} />

      {recargoPercentageId !== "" ? (
        <Line label={t("invoices.recargo")} value={recargoAmount} />
      ) : null}

      {irpfAmount != null && <Line label={t("invoices.totalIrpf")} value={-irpfAmount} />}

      <Box direction="row" style={[styles.gap, styles.total]}>
        <Text style={styles.totalText}>{t("invoices.total")}</Text>

        <Text style={[styles.totalText, common.textRight]}>
          {formatCurrency(Number(total ?? 0), "EUR")}
        </Text>
      </Box>
    </Box>
  );
};

const Line = ({ label, value }: { label: string; value?: string | number }) =>
  value != null && (
    <Box direction="row" style={styles.gap}>
      <Text style={styles.resume}>{label}</Text>
      <Text style={[styles.resume, common.textRight]}>{formatCurrency(Number(value), "EUR")}</Text>
    </Box>
  );
