import { DecimalInput } from "@components/DecimalInput";
import { RightPanel } from "@components/RightPanel";
import { Button } from "@components/forms/Button";
import Select from "@components/forms/Select";
import { Product, ProductsDocument, SalesInvoiceCreateStaticDocument } from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useState } from "react";
import { GestureResponderEvent, Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { useNestedForm } from "../../../components/NestedForm";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { CloseIcon } from "../../../utils/icons";
import {
  passValidatorTrue,
  validateNullableRequired,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { Concept, CreateSalesConceptState, CreateSalesRecargoConceptState } from "../types";
import { extractSalesStatic, extractSalesTax } from "../utils";
import { ConceptTotal } from "./ConceptTotal";
import { ProductCreate } from "./ProductCreate";
import { SalesRightPanel } from "./SalesRightPanel";

const styles = StyleSheet.create({
  line: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 12,
  },
  lineSelected: {
    backgroundColor: backgroundColorVariants.primary100,
    borderWidth: 2,
    borderColor: borderColorVariants.primary500,
    borderRadius: 8,
    padding: 12,
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  action: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 4,
    marginBottom: 6,
  },
  actionText: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1.5fr 1.5fr 1.5fr",
    gap: 12,
  },
  row2: {
    gridTemplateColumns: "2fr 2fr 1fr 1.5fr",
  },
  rowRecargo: {
    gridTemplateColumns: "2fr 1.5fr 2fr 1fr 1.5fr",
  },
  gap: {
    gap: 8,
  },
  options: {
    maxHeight: 0,
    overflow: "hidden",
    transitionDuration: "0.3s",
    transitionProperty: "max-height",
  },
  optionsOpened: {
    maxHeight: 800,
  },
  moreOptions: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
});

type Props = {
  large?: boolean;
  concept: Concept;
  deleteDisabled: boolean;
  onChange: (concept: Concept) => void;
  onDelete: () => void;
  isActive: boolean;
  setCurrentId: () => void;
  disabled?: boolean;
  recargo?: boolean;
  invoiceType: string;
  validate: boolean;
};

export const SalesConceptItem = ({
  large = true,
  concept,
  deleteDisabled,
  onChange,
  onDelete,
  isActive,
  setCurrentId,
  disabled = false,
  recargo = false,
  invoiceType,
  validate,
}: Props) => {
  const [showProductCreate, setShowProductCreate] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);
  const [open, setOpen] = useState(false);

  const { Field, setFieldValue, getFieldState, listenFields, submitForm } = useForm<
    CreateSalesConceptState | CreateSalesRecargoConceptState
  >({
    id: { initialValue: concept.id },
    productId: {
      initialValue: concept.productId,
      validate: passValidatorTrue(validateNumericNullableRequired, validate),
    },
    description: {
      initialValue: concept.description,
      validate: passValidatorTrue(validateRequired, validate),
    },
    unitType: { initialValue: concept.unitType ?? "" },
    unitPrice: { initialValue: concept.unitPrice ?? "" },
    price: { initialValue: concept.price },
    vatId: {
      initialValue: String(concept.vatId) ?? "",
      validate: passValidatorTrue(validateRequired, validate),
    },
    recargoPercentageId: {
      initialValue: String(concept.recargoPercentageId) ?? "",
      validate: passValidatorTrue(validateNullableRequired, validate && recargo),
    },
    quantity: {
      initialValue: concept.quantity ?? 1,
      validate: passValidatorTrue(validateNumericNullableRequired, validate),
    },
    irpfId: { initialValue: String(concept.irpfId) ?? "" },
    discountAmount: { initialValue: concept.discountAmount },
    discountPercentage: { initialValue: concept.discountPercentage },
    expenseType: { initialValue: concept.expenseType ?? "corriente" },
    type: { initialValue: concept.type },
    subtotal: { initialValue: concept.subtotal ?? "" },
    category: {
      initialValue: concept?.category,
      validate: passValidatorTrue(validateNullableRequired, validate),
    },
    subcategory: {
      initialValue: concept?.subcategory,
      validate: passValidatorTrue(validateNullableRequired, validate),
    },
    subsubcategory: {
      initialValue: concept?.subsubcategory,
      validate: passValidatorTrue(validateNullableRequired, validate),
    },
  });

  useEffect(() => {
    setFieldValue("subtotal", concept.subtotal ?? "");
    setSubtotalAux(concept.subtotal ?? "");
  }, [concept.subtotal]);

  useEffect(() => {
    setFieldValue("unitPrice", concept.unitPrice ?? "");
    setUnitPriceAux(concept.unitPrice ?? "");
  }, [concept.unitPrice]);

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  const { data: productsQuery, reload } = useUrqlQuery(
    {
      query: ProductsDocument,
      variables: {
        first: 100 as never,
        filters: {} as never,
      },
    },
    [],
  );

  const products = productsQuery
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ products }) => Option.fromNullable(products))
    .map(products => {
      if ("edges" in products && Array.isArray(products.edges)) {
        return products.edges.map(({ node }) => node as unknown as Product);
      } else if (Array.isArray(products)) {
        return products;
      }
      return [];
    })
    .getWithDefault([]) as Product[] | { id: number; description: string }[];

  const productsItems = products?.map(product => ({
    name: product.description,
    value: Number(product.id),
  }));
  productsItems.unshift({ name: "Manual", value: -1 });

  const irpfType = extractSalesTax(data, "irpfType");
  const vatType = extractSalesTax(data, "vatType");
  const unitType = extractSalesStatic(data, "unitType");
  const discountType = extractSalesStatic(data, "discountType");
  const recargoEquivalenciaType = extractSalesTax(data, "recargoEquivalenciaType");

  const setRecargoPercentageId = (value: string) => {
    if (recargo) {
      const recargoRow = recargoEquivalenciaType.find(({ parentId }) => Number(value) === parentId);
      setFieldValue("recargoPercentageId", recargoRow?.id);
    }
  };

  useEffect(() => {
    const removeListenerProduct = listenFields(["productId"], ({ productId }) => {
      if (!products.length || concept.productId === productId?.value) {
        return;
      }
      const prod = products.filter(p => p.id === productId?.value?.toString())?.[0] as Product;
      if (prod !== undefined) {
        const prodVatId = isNotNullish(prod.vatId) ? String(prod.vatId) : "";
        setFieldValue("description", prod.description ?? "");
        setFieldValue("unitType", prod.unitType ?? "");
        setFieldValue("unitPrice", prod.unitPrice as string);
        setFieldValue("vatId", prodVatId);
        setFieldValue("irpfId", isNotNullish(prod.irpfId) ? String(prod.irpfId) : "");
        setFieldValue("subtotal", "");
        setFieldValue("category", isNotNullish(prod?.category) ? String(prod.category) : "");
        setFieldValue(
          "subcategory",
          isNotNullish(prod?.subcategory) ? String(prod.subcategory) : "",
        );
        setFieldValue(
          "subsubcategory",
          isNotNullish(prod?.subsubcategory) ? String(prod.subsubcategory) : "",
        );
        setRecargoPercentageId(prodVatId);
      }
    });

    return () => removeListenerProduct();
  }, [products, concept.productId, listenFields, setFieldValue]);

  useEffect(() => {
    const removeListener = listenFields(
      [
        "productId",
        "description",
        "quantity",
        "unitType",
        "vatId",
        "irpfId",
        "discountAmount",
        "discountPercentage",
        "recargoPercentageId",
        "category",
        "subcategory",
        "subsubcategory",
      ],
      () => submitForm(values => onChange(values as Concept)),
    );

    return () => removeListener();
  }, [listenFields, onChange, submitForm]);

  const [discountTypeValue, setDiscountTypeValue] = useState("percentage");

  const [unitPriceAux, setUnitPriceAux] = useState<string>(String(concept.unitPrice ?? ""));
  const [subtotalAux, setSubtotalAux] = useState<string>(String(concept.subtotal ?? ""));

  useEffect(() => {
    if (!unitPriceAux && !subtotalAux) {
      return;
    }

    setFieldValue("unitPrice", unitPriceAux);
    setFieldValue("subtotal", subtotalAux);

    submitForm(values => onChange(values as Concept));
  }, [unitPriceAux, subtotalAux]);

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  useEffect(() => {
    submitForm(values => {
      return onChange(values as Concept);
    });
  }, [validate]);

  const { addForm, addError } = useNestedForm();

  useEffect(() => {
    addForm(() => {
      submitForm(
        values => onChange(values as Concept),
        errors => {
          addError("error");
          if (
            errors.category != undefined ||
            errors.subcategory != undefined ||
            errors.subsubcategory != undefined
          ) {
            setCurrentId();
            setOpen(true);
          }
        },
      );
    });
  }, []);

  const stopPropagation = (event: GestureResponderEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <Pressable
        style={isActive ? styles.lineSelected : styles.line}
        onPress={() => {
          setCurrentId();
          setOpen(true);
        }}
      >
        <Field name="id">{() => null}</Field>
        <Field name="type">{() => null}</Field>
        <Field name="category">{() => null}</Field>
        <Field name="subcategory">{() => null}</Field>
        <Field name="subsubcategory">{() => null}</Field>

        <Box
          direction={large ? "row" : "column"}
          alignItems={large ? "start" : "stretch"}
          justifyContent="spaceBetween"
          style={styles.row}
        >
          <View>
            <Field name="description">
              {({ value, onChange, error }) => {
                if (getFieldState("productId").value != null) {
                  return (
                    <LakeLabel
                      label={t("invoices.description")}
                      style={styles.column}
                      render={id => (
                        <Pressable onPress={stopPropagation}>
                          <LakeTextInput
                            id={id}
                            value={value}
                            error={error}
                            onChangeText={onChange}
                            disabled={disabled}
                          />
                        </Pressable>
                      )}
                    />
                  );
                }
                return <></>;
              }}
            </Field>

            <Field name="productId">
              {({ value, onChange, error }) => {
                if (value == null) {
                  return (
                    <LakeLabel
                      label={t("invoices.productOrService")}
                      style={[styles.column, styles.actions]}
                      render={() => (
                        <LakeSelect
                          items={productsItems}
                          value={value}
                          hideErrors={error === undefined}
                          error={error}
                          onValueChange={onChange}
                          disabled={disabled}
                        />
                      )}
                      actions={
                        <Pressable style={styles.action} onPress={() => setShowProductCreate(true)}>
                          <Icon name="add-filled" size={12} color={fontColorVariants.neutral500} />
                          <Text style={styles.actionText}>{t("invoices.newProduct")}</Text>
                        </Pressable>
                      }
                    />
                  );
                }
                return <></>;
              }}
            </Field>

            <Pressable onPress={stopPropagation}>
              <ProductCreate
                visible={showProductCreate}
                onPressClose={() => {
                  setShowProductCreate(false);
                  reload();
                }}
              />
            </Pressable>
          </View>

          <Field name="quantity">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.quantity")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={String(value)}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setFieldValue("subtotal", "");
                        onChange(Number(nextValue));
                      }}
                      disabled={disabled}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="unitType">
            {Select({
              label: t("invoices.unitType"),
              items: unitType,
              disabled,
              hasEmptyRow: true,
            })}
          </Field>

          <Field name="unitPrice">
            {({ error }) => (
              <LakeLabel
                label={t("invoices.unitPrice")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={unitPriceAux}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setSubtotalAux("");
                        setUnitPriceAux(nextValue);
                      }}
                      disabled={disabled}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="subtotal">
            {({ error }) => (
              <LakeLabel
                label={t("invoices.price")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={subtotalAux}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setUnitPriceAux("");
                        setSubtotalAux(nextValue);
                      }}
                      disabled={disabled}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>
        </Box>

        <Box
          direction={large ? "row" : "column"}
          alignItems={large ? "start" : "stretch"}
          justifyContent="spaceBetween"
          style={[styles.row, recargo ? styles.rowRecargo : styles.row2]}
        >
          <Field name="vatId">
            {Select({
              label: t("invoices.iva"),
              items: vatType.map(({ id, description }) => ({ name: description, value: id })),
              customOnChange: value => {
                setFieldValue("vatId", value);
                setRecargoPercentageId(value);
              },
              disabled,
            })}
          </Field>

          {recargo && (
            <Field name="recargoPercentageId">
              {({ value, onChange, error, ref }) => (
                <LakeLabel
                  label={t("invoices.recargo")}
                  render={id => (
                    <LakeSelect
                      id={id}
                      ref={ref}
                      value={value}
                      hideErrors={error === undefined}
                      error={error}
                      items={recargoEquivalenciaType.map(({ id, description }) => ({
                        name: description,
                        value: id,
                      }))}
                      onValueChange={onChange}
                      disabled={true}
                    />
                  )}
                />
              )}
            </Field>
          )}

          <Field name="irpfId">
            {Select({
              label: t("invoices.irpf"),
              items: irpfType.map(({ id, description }) => ({ name: description, value: id })),
              disabled,
              hasEmptyRow: true,
            })}
          </Field>

          {discountTypeValue === "amount" && (
            <Field name="discountAmount">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("invoices.discount")}
                  style={styles.column}
                  render={id => (
                    <Pressable onPress={stopPropagation}>
                      <DecimalInput
                        id={id}
                        value={value?.toString()}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeDecimal={value => {
                          isNaN(Number(value)) ? onChange(undefined) : onChange(Number(value));
                        }}
                        disabled={disabled}
                      />
                    </Pressable>
                  )}
                />
              )}
            </Field>
          )}

          {discountTypeValue === "percentage" && (
            <Field name="discountPercentage">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("invoices.discount")}
                  style={styles.column}
                  render={id => (
                    <Pressable onPress={stopPropagation}>
                      <DecimalInput
                        id={id}
                        value={value?.toString()}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeDecimal={value => {
                          isNaN(Number(value)) ? onChange(undefined) : onChange(Number(value));
                        }}
                        disabled={disabled}
                      />
                    </Pressable>
                  )}
                />
              )}
            </Field>
          )}

          <LakeLabel
            label={t("invoices.discountType")}
            render={() => (
              <LakeSelect
                items={discountType}
                value={discountTypeValue}
                onValueChange={value => {
                  setFieldValue("discountAmount", undefined);
                  setFieldValue("discountPercentage", undefined);
                  setDiscountTypeValue(value);
                }}
                hideErrors={true}
                disabled={disabled}
              />
            )}
          />
        </Box>

        <Box style={[styles.options, moreOptions && styles.optionsOpened]}>
          <Space height={24} />

          <Box style={styles.moreOptions}>
            <View></View>

            <View>
              <ConceptTotal concept={concept} />
            </View>
          </Box>
        </Box>

        <Space height={24} />

        <Box direction="row" justifyContent="end" style={styles.gap}>
          <Button
            onPress={() => setMoreOptions(!moreOptions)}
            mode="tertiary"
            icon={<Icon name={moreOptions ? "arrow-up-regular" : "arrow-down-regular"} size={16} />}
            reverse={true}
          >
            {t("invoices.viewTotal")}
          </Button>

          <Button
            onPress={onDelete}
            mode="tertiary"
            disabled={deleteDisabled}
            icon={<Icon name="delete-regular" size={16} />}
            reverse={true}
          >
            {t("invoices.removeItem")}
          </Button>
        </Box>
      </Pressable>

      <RightPanel visible={isActive && open} onPressClose={() => setOpen(false)}>
        <View style={styles.rightpanel}>
          <Pressable onPress={() => setOpen(false)}>
            <CloseIcon />
          </Pressable>

          <SalesRightPanel
            invoiceType={invoiceType}
            form={{
              Field,
              setFieldValue,
              getFieldState,
              listenFields,
              onChange: () => submitForm(values => onChange(values as Concept)),
            }}
          />
        </View>
      </RightPanel>
    </>
  );
};
