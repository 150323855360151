import { Sidebar } from "@components/Sidebar";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { Navigation } from "../components/Navigation";
import { DocumentsList } from "./DocumentsList";
import { ProductsList } from "./ProductsList";
import { PurchaseList } from "./PurchaseList";
import { SalesList } from "./SalesList";
import { TicketsList } from "./TicketList";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
  },
});

export const Area = () => {
  const { desktop } = useResponsive();

  const route = Router.useRoute([
    "InvoicesSalesArea",
    "InvoicesDocumentsArea",
    "InvoicesProductsArea",
    "InvoicesPurchaseArea",
    "InvoicesTicketList",
  ]);

  if (!route) {
    Router.replace("InvoicesSalesList");
  }

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <Navigation />
            </Sidebar>
          )}

          <View style={styles.root}>
            <View style={commonStyles.fill} role="main">
              {match(route)
                .with({ name: "InvoicesSalesArea" }, () => <SalesList />)
                .with({ name: "InvoicesDocumentsArea" }, () => <DocumentsList />)
                .with({ name: "InvoicesProductsArea" }, () => <ProductsList />)
                .with({ name: "InvoicesPurchaseArea" }, () => <PurchaseList />)
                .with({ name: "InvoicesTicketList" }, () => <TicketsList />)
                .run()}
            </View>
          </View>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
