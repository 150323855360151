import { PurchaseCategory, Static } from "@graphql/crm";
import { match } from "ts-pattern";

export type Item<T> = {
  label: string;
  value: T;
};

export const getAlign = (align?: "left" | "right" | "center") =>
  match(align)
    .with("right", () => "flex-end")
    .with("center", () => "center")
    .otherwise(() => "flex-start");

export const staticToItem = <T>(data: Static[]) =>
  data?.map(row => {
    return { value: row.value.toUpperCase(), label: row.name } as Item<T>;
  });

export const categoryToItem = <T>(data: PurchaseCategory[]) =>
  data?.map((row: PurchaseCategory) => {
    return { value: row.id, label: row.description } as Item<T>;
  });

export const staticToItemLower = <T>(data: Static[]) =>
  data?.map(row => {
    return { value: row.value, label: row.name } as Item<T>;
  });
