import { ChoicePicker } from "@components/ChoicePicker";
import { DecimalInput } from "@components/DecimalInput";
import { IconChoice } from "@components/IconChoice";
import { Button } from "@components/forms/Button";
import {
  PurchaseCategory,
  PurchaseInvoiceCreateStaticDocument,
  PurchaseSubCategory,
} from "@graphql/crm";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { PurchaseCategoryConcept } from "../../../features/invoices/types";
import { common } from "../../../styles/common";
import { t } from "../../../utils/i18n";
import { extractQueryData } from "../../../utils/validations";
import { CategorySuggestion } from "./PurchaseCategorySuggestion";

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "600",
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 8,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  depreciableContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

type Props = {
  form: PurchaseCategoryConcept;
  invoiceType?: string;
};

export const PurchaseRightPanel = ({ form, invoiceType }: Props) => {
  const [currentSubCategory, setCurrentSubCategory] = useState<PurchaseSubCategory>();
  const [categories, setCategories] = useState<PurchaseCategory[]>([]);
  const [currentCategory, setCurrentCategory] = useState<PurchaseCategory>();

  const { data } = useUrqlQuery({ query: PurchaseInvoiceCreateStaticDocument }, []);

  const PurchaseCreateStaticData = extractQueryData(
    data,
    "value.value.purchaseInvoiceCreateStatic",
  );
  const currentCategories = extractQueryData(
    PurchaseCreateStaticData,
    "currentCategories",
  ) as PurchaseCategory[];
  const nonCurrentCategories = extractQueryData(
    PurchaseCreateStaticData,
    "nonCurrentCategories",
  ) as PurchaseCategory[];

  useEffect(() => {
    setCategories(invoiceType === "current" ? currentCategories : nonCurrentCategories);
  }, [invoiceType]);

  useEffect(() => {
    const category = form.getFieldState("category").value;
    const categoryObj = categories.find(c => c.id === category);
    setCurrentCategory(categoryObj);
    const subcategory = form.getFieldState("subcategory").value;
    setCurrentSubCategory(categoryObj?.subcategory.find(c => c.id === subcategory));
  }, [categories]);

  useEffect(() => {
    const listener = form.listenFields(
      ["category", "subcategory", "subsubcategory"],
      ({ category, subcategory }) => {
        const categoryObj = categories.find(c => c.id === category?.value);
        setCurrentCategory(categoryObj);
        if (subcategory !== undefined) {
          setCurrentSubCategory(categoryObj?.subcategory.find(c => c.id === subcategory.value));
        }
      },
    );

    return () => listener();
  }, [form.listenFields, data]);

  const renderItem = (item: string, selected?: string) => {
    const category = categories.find(c => c.id === item);
    return (
      category && (
        <IconChoice
          sourceUri={category.iconUrl}
          name={category.description}
          isSelected={category?.id === selected}
        />
      )
    );
  };

  const renderItemSubcategories = (item: string, selected?: string) => {
    const subCategory = currentCategory?.subcategory.find(c => c.id === item);
    return (
      subCategory && (
        <IconChoice
          sourceUri={subCategory.iconUrl}
          name={subCategory.description}
          isSelected={subCategory?.id === selected}
        />
      )
    );
  };

  const renderItemSubSubcategories = (item: string, selected?: string) => {
    const subSubCategory = currentSubCategory?.subsubcategory.find(c => c.id === item);
    return (
      subSubCategory && (
        <IconChoice
          sourceUri=""
          name={subSubCategory.description}
          isSelected={subSubCategory?.id === selected}
        />
      )
    );
  };

  const getSubtitle = (subcategory?: string) => {
    if (currentCategory !== undefined) {
      const subcategoryName = currentCategory.subcategory.find(
        c => c.id === subcategory,
      )?.description;
      const sub = subcategoryName !== undefined ? ` / ${subcategoryName}` : "";
      return `${currentCategory.description}${sub}`;
    }
    return "";
  };

  const getLastTitle = (subsubcategory?: string) => {
    if (currentSubCategory !== undefined) {
      const subtitle = getSubtitle(currentSubCategory.id);
      const subsubcategoryName = currentSubCategory.subsubcategory.find(
        c => c.id === subsubcategory,
      )?.description;
      const sub = subsubcategoryName !== undefined ? ` / ${subsubcategoryName}` : "";
      return `${subtitle}${sub}`;
    }
    return "";
  };

  const [depreciable, setDepreciable] = useState(false);

  const checkDepreciable = (category: string) => {
    const categoryData = categories.find(c => c.id === category);
    if (categoryData) {
      setDepreciable(categoryData.depreciable);
    }
  };

  useEffect(() => {
    checkDepreciable(form.getFieldState("category")?.value as string);
  }, [form.getFieldState("category")?.value]);

  return (
    <>
      <Space height={24} />

      <CategorySuggestion
        categories={[...currentCategories, ...nonCurrentCategories]}
        concept={form}
      />

      <Space height={24} />

      <form.Field name="category">
        {({ value, onChange, error }) =>
          currentCategory === undefined || currentCategory.subcategory.length === 0 ? (
            <>
              <Text style={styles.title}>{t("invoices.purchaseCategory")}</Text>

              <ChoicePicker
                items={categories.map(({ id }) => id)}
                renderItem={item => renderItem(item, value)}
                value={value}
                onChange={value => {
                  onChange(value);
                  form.onChange();
                }}
              />

              <Text style={common.error}>{error}</Text>
            </>
          ) : null
        }
      </form.Field>

      <form.Field name="subcategory">
        {({ value, onChange, error }) =>
          currentCategory !== undefined &&
          currentCategory.subcategory.length > 0 &&
          currentSubCategory === undefined ? (
            <>
              <Text style={styles.title}>{t("invoices.subcategory")}</Text>
              <Text style={styles.subtitle}>{getSubtitle(value)}</Text>

              <ChoicePicker
                items={currentCategory.subcategory.map(({ id }) => id)}
                renderItem={item => renderItemSubcategories(item, value)}
                value={value}
                onChange={value => {
                  onChange(value);
                  form.onChange();
                }}
              />

              <Text style={common.error}>{error}</Text>
              <Space height={16} />

              <Button
                size="small"
                mode="tertiary"
                style="outlined"
                onPress={() => {
                  form.setFieldValue("category", undefined);
                  form.setFieldValue("subcategory", undefined);
                  form.setFieldValue("subsubcategory", undefined);
                }}
              >
                {t("invoices.backToCategories")}
              </Button>
            </>
          ) : null
        }
      </form.Field>

      <form.Field name="subsubcategory">
        {({ value, onChange, error }) =>
          currentSubCategory ? (
            <>
              <Text style={styles.title}>{t("invoices.subsubcategory")}</Text>
              <Text style={styles.subtitle}>{getLastTitle(value)}</Text>

              <ChoicePicker
                items={currentSubCategory.subsubcategory.map(({ id }) => id)}
                renderItem={item => renderItemSubSubcategories(item, value)}
                value={value}
                onChange={value => {
                  onChange(value);
                  form.onChange();
                }}
              />

              <Text style={common.error}>{error}</Text>
              <Space height={16} />

              <Button
                size="small"
                mode="tertiary"
                style="outlined"
                onPress={() => {
                  setCurrentSubCategory(undefined);
                  form.setFieldValue("subcategory", undefined);
                  form.setFieldValue("subsubcategory", undefined);
                }}
              >
                {t("invoices.backToCategories")}
              </Button>
            </>
          ) : null
        }
      </form.Field>

      {depreciable && (
        <View style={styles.depreciableContainer}>
          <form.Field name="usefulLife">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.usefulLife")}
                style={styles.column}
                render={id => (
                  <DecimalInput
                    id={id}
                    value={value?.toString()}
                    hideErrors={error === undefined}
                    error={error}
                    onChangeDecimal={value => {
                      onChange(Number(value));
                    }}
                  />
                )}
              />
            )}
          </form.Field>

          <form.Field name="amortizationRate">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.amortizationRate")}
                style={styles.column}
                render={id => (
                  <DecimalInput
                    id={id}
                    value={value?.toString()}
                    hideErrors={error === undefined}
                    error={error}
                    onChangeDecimal={value => {
                      onChange(Number(value));
                    }}
                  />
                )}
              />
            )}
          </form.Field>
        </View>
      )}
    </>
  );
};
