import { Calendar } from "@components/Calendar";
import { ErrorView } from "@components/ErrorView";
import { Heading } from "@components/Heading";
import { Button } from "@components/forms/Button";
import { InputRounded } from "@components/forms/Input";
import {
  InputMaybe,
  SalesCategory,
  SalesInvoiceFilterStaticDocument,
  SalesInvoiceRelayQuery,
  SalesInvoicesDocument,
  SendStatus,
  Status,
} from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useUrqlPaginatedQuery, useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useCallback, useMemo, useState } from "react";
import { FaArrowDown } from "react-icons/fa6";
import { Pressable, StyleSheet, View } from "react-native";
import { toSnake } from "ts-case-convert";
import { match } from "ts-pattern";
import { useDateFilter } from "../../../hooks/useDateFilter";
import { ManageParamsOauthSwan } from "../../../utils/auth";
import { formatDate } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { SalesCreate } from "../components/SalesCreate";
import { DetailsList } from "../components/SalesDetailsList";
import { InvoiceFiltersState, ListFilter } from "../components/SalesListFilter";
import { SalesStats } from "../components/SalesStats";
import { useExport } from "../hooks/useExport";
import { extractSalesStatic } from "../utils";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
  },
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  container: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
});

const NUM_TO_RENDER = 7;

export const SalesList = () => {
  ManageParamsOauthSwan("InvoicesSalesList");

  const { from, to } = useDateFilter();

  const route = Router.useRoute(["InvoicesSalesList"]);
  const params = match(route)
    .with({ name: "InvoicesSalesList" }, ({ params }) => params)
    .otherwise(() => {});

  const filters: InvoiceFiltersState = useMemo(() => {
    return {
      search: params?.search,
      status: params?.status,
      sendStatus: params?.sendStatus,
      category: params?.category,
      isRectified: params?.isRectified,
    } as InvoiceFiltersState;
  }, [params]);

  const sort = useMemo(() => {
    return {
      field: params?.sortBy ?? "issue_date",
      direction: params?.sortOrder ?? "-",
    };
  }, [params?.sortBy, params?.sortOrder]);

  const { data: staticData } = useUrqlQuery({ query: SalesInvoiceFilterStaticDocument }, []);

  const categoryItems = extractSalesStatic(staticData, "category") as unknown as SalesCategory[];
  const statusItems = extractSalesStatic(staticData, "statusType");
  const sendStatusItems = extractSalesStatic(staticData, "sendStatusType");

  const { data, nextData, reload, isForceReloading, setAfter } = useUrqlPaginatedQuery(
    {
      query: SalesInvoicesDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          status: { inList: params?.status as InputMaybe<Status[]> },
          sendStatus: { inList: params?.sendStatus as InputMaybe<SendStatus[]> },
          category: { inList: params?.category as InputMaybe<string[]> | undefined },
          search: params?.search,
          issueDate: { gte: from, lte: to },
          rectifiedInvoice: {
            isNull:
              params?.isRectified != null
                ? params?.isRectified[0] == "true"
                  ? false
                  : true
                : undefined,
          },
        },
        orderBy: `${sort.direction}${sort.field}`,
      },
    },
    [filters, from, to, sort],
  );

  const [activeInvoiceId, setActiveInvoiceId] = useState<string | null>(null);
  const [invoice, setInvoice] = useState<SalesInvoiceRelayQuery | undefined>(undefined);

  const onActiveRowChange = useCallback(() => {}, []);

  data.mapOk(data => {
    if (data?.salesInvoices?.edges[0]?.node?.__typename === "SwanOauth") {
      window.location.replace(data.salesInvoices.edges[0].node.url);
    }
  });

  const STATUS_LIST = ["PENDING", "CHARGED", "OVERDUED", "DEFAULTED"];

  const invoices = data
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ salesInvoices }) => Option.fromNullable(salesInvoices))
    .map(({ edges }) =>
      edges
        .filter(
          ({ node }) =>
            node.__typename === "SalesInvoiceRelayQuery" &&
            STATUS_LIST.includes(node.status as string),
        )
        .map(({ node }) => node),
    )
    .getWithDefault([]);

  const onViewDetails = useCallback(
    (currentId: string) => {
      const currentInvoice = invoices.filter(
        row => row.__typename === "SalesInvoiceRelayQuery" && row.id === currentId,
      )?.[0] as SalesInvoiceRelayQuery;
      if (Boolean(currentInvoice?.rectifiedInvoiceId)) {
        Router.push("InvoicesSalesRectifyDetail", { rectifiedId: currentInvoice.id as string });
      } else if (currentInvoice?.status === "PROFORMA") {
        Router.push("InvoicesSalesProformaDetail", { proformaId: currentInvoice.id as string });
      } else if (currentInvoice?.status === "QUOTE") {
        Router.push("InvoicesSalesQuoteDetail", { quoteId: currentInvoice.id as string });
      } else if (currentInvoice?.status === "DELIVERY") {
        Router.push("InvoicesSalesDeliveryDetail", { deliveryId: currentInvoice.id as string });
      } else {
        setInvoice(currentInvoice);
        Router.push("InvoicesSalesList", { ...filters, visible: "1" });
      }
    },
    [filters, invoices],
  );

  const { handleExportInvoices } = useExport();

  return (
    <View style={styles.root}>
      <View style={commonStyles.fill} role="main">
        <Box
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          style={styles.header}
        >
          <InputRounded>
            <LakeSearchField
              placeholder={t("common.search")}
              initialValue={""}
              onChangeText={search =>
                Router.push("InvoicesSalesList", {
                  ...filters,
                  search,
                })
              }
            />
          </InputRounded>

          <Box direction="row" justifyContent="end" alignItems="end">
            <Calendar />
            <Space width={8} />

            <ListFilter
              filters={filters}
              categoryItems={categoryItems}
              statusItems={statusItems.filter(f => STATUS_LIST.includes(f.value.toUpperCase()))}
              sendStatusItems={sendStatusItems}
              onChange={filters => Router.push("InvoicesSalesList", filters)}
            />
          </Box>
        </Box>

        <Heading title={t("invoices.sales")}>
          <SalesStats forceReload={isForceReloading} search={params?.search} gte={from} lte={to} />
        </Heading>

        <Box style={styles.container}>
          <Box direction="row" justifyContent="end" alignItems="center">
            <Button
              icon={<FaArrowDown size={16} />}
              reverse={true}
              mode="tertiary"
              onPress={() => {
                handleExportInvoices({
                  dateFrom: formatDate(from, "YYYY-MM-DD"),
                  dateTo: formatDate(to, "YYYY-MM-DD"),
                });
              }}
            >
              {t("common.export")}
            </Button>
          </Box>

          <Space height={8} />

          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={NUM_TO_RENDER}
                rowVerticalSpacing={0}
                headerHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: data => (
                  <DetailsList
                    invoices={invoices as SalesInvoiceRelayQuery[]}
                    setActiveInvoice={setInvoice}
                    onActiveRowChange={onActiveRowChange}
                    activeRowId={activeInvoiceId ?? undefined}
                    onRefreshRequest={reload}
                    onEndReached={() => {
                      if (data?.salesInvoices.pageInfo.hasNextPage === true) {
                        setAfter(data?.salesInvoices?.pageInfo.endCursor ?? undefined);
                      }
                    }}
                    loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER }}
                    getRowLink={({ item }) => (
                      <Pressable
                        onPress={() => {
                          setActiveInvoiceId(item.id as string);
                          onViewDetails(item.id as string);
                        }}
                      />
                    )}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                    sortBy={`${sort.direction}${sort.field}`}
                    setSort={columnId => {
                      const sortBy = toSnake(columnId);
                      const sortOrder = sort.field === sortBy && sort.direction === "-" ? "" : "-";
                      Router.push("InvoicesSalesList", {
                        ...filters,
                        sortBy,
                        sortOrder,
                      });
                    }}
                  />
                ),
              }),
          })}
        </Box>
      </View>

      <SalesCreate
        visible={params?.visible !== undefined}
        invoice={invoice}
        onRefreshRequest={reload}
      />
    </View>
  );
};
